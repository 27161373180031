import Earth1 from "../../Component/EarthCom/Earth1";
import { Helmet } from "react-helmet";

function Home() {
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "DA Tech",
    url: "https://thedatech.com",
    logo: "https://thedatech.com/logoicon.png",
    description:
      "DA Tech offers custom development, eCommerce management, SEO, ASO, and more to enhance your digital presence and drive business growth.",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+92-304-888-0004",
      contactType: "Owner",
      areaServed: ["US", "CA", "EU", "AE", "AU", "NZ"],
      availableLanguage: ["English"],
    },
    sameAs: [
      "https://wa.me/923048880004",
      "https://www.facebook.com/thedatech",
      "https://www.linkedin.com/company/thedatech",
      "https://www.twitter.com/thedatech",
      "https://www.instagram.com/the_datech",
      "https://fiverr.com/datech",
      "https://www.bark.com/en/gb/company/da-tech/lKYJG/",
      "https://www.upwork.com/freelancers/~0121b5ac3c5d855695",
      "https://www.guru.com/freelancers/da-tech",
      "https://clutch.co/profile/da-tech",
    ],
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "5",
      reviewCount: "150",
    },
  };

  return (
    <>
      <Helmet>
        <title>
          Top-Rated IT Company | Website Design, Apps Development & Digital
          Marketing Agency Near Me
        </title>
        <meta
          name="description"
          content="Top-Rated IT Company | Digital Marketing Agency - We will execute your idea into reality, from design to
marketing. USA - CA - EU - UAE - PK - AU - NZ"
        />
        <meta
          name="keywords"
          content="website design, apps development, top-rated IT company, digital marketing agency near me, nearest IT
company, United States, Canada, Europe, United Arab Emirates, Pakistan, Australia, New Zealand"
        />
      </Helmet>
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
      <Earth1 />
    </>
  );
}

export default Home;
