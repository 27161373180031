import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Link, useParams } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Helmet } from "react-helmet";

const BlogDetailSkeleton = () => {
  const { title } = useParams();

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  return (
    <div className="servicemargin">
      <Helmet>
        <title>Loading...</title>
      </Helmet>

      {/* Skeleton Theme Wrapper */}
      <SkeletonTheme baseColor="#000000A5" highlightColor="#808080">
        <div className="row mx-2 my-2">
          <div className="col-1 mt-2"></div>
          <div className="col-11 d-flex justify-content-center">
            <Skeleton width={1000} height={30} />
          </div>
        </div>

        <div className="row">
          {/* Left Section */}
          <div className="col-md-6 blogdetailscroller">
            <Skeleton width={"100%"} height={40} />
            <Skeleton width={"100%"} height={"100%"} />
          </div>

          {/* Right Section */}
          <div className="col-md-6 desktopview">
            <Skeleton height={"100%"} />
          </div>
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default BlogDetailSkeleton;
