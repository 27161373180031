import React from "react";
import { IoMdClose } from "react-icons/io";
import "./Modal.css";

const Modal = ({ showModal, closeModal, children }) => {
  if (!showModal) return null;

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  return (
    <div className="modal-backdrop" onClick={handleBackdropClick}>
      <div className="modal-content">
        <button className="modal-close" onClick={closeModal}>
          <IoMdClose />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
