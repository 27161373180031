import { useState, useEffect, useRef } from "react";
import Globe from "react-globe.gl";
import data from "./datasets/ne_110m_populated_places_simple.geojson";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import { Autoplay, FreeMode } from "swiper/modules";
import { Custobtn } from "../../Pages/Application/Custobtn";
import { GetStartedbtn } from "../../Pages/Application/Getstartedbtn";
import Lottie from "lottie-react";
import idea from "../FILEJSON/home/home idea.json";
import design from "../FILEJSON/home/home design.json";
import code from "../FILEJSON/home/homecodeicon.json";
import market from "../FILEJSON/home/home marketing.json";
import services from "../FILEJSON/services/services.json";
import getStarted from "../FILEJSON/other pages and includes/Getstarted.json";
import customer from "../../Component/FILEJSON/services/custom.json";
import website from "../../Component/FILEJSON/services/website.json";
import mobileapp from "../../Component/FILEJSON/services/mobileapp.json";
import ebook from "../../Component/FILEJSON/services/ebook.json";
import googlelinkedin from "../../Component/FILEJSON/services/google linkedin youtube ads.json";
import support from "../../Component/FILEJSON/services/livechat.json";
import socialmedia from "../../Component/FILEJSON/services/smm.json";
import uiux from "../../Component/FILEJSON/services/home design.json";
import seo from "../../Component/FILEJSON/services/home marketing.json";
import maintenance from "../../Component/FILEJSON/services/support.json";
import back from "../../Component/FILEJSON/other pages and includes/Back.json";
import eCommercemanagement from "../../Component/FILEJSON/other pages and includes/ecommercemanagement.json";
import customPatches from "../../Component/FILEJSON/other pages and includes/custompatchesServices.json";
import "../../Pages/Services/service.css";
import "../../App.css";

const servicesData = [
  {
    title: "Custom Development",
    animationData: customer,
    link: "/services/custom-development",
    description:
      "Robust custom development for web applications, CRM, ERP, Portals, eCommerce, Service Booking, HRM, Live Chat Box, Website Admin Panel, POS System or any backend.",
  },
  {
    title: "Website Design and Development",
    animationData: website,
    link: "/services/website-design-and-development",
    description:
      "Tailoring fully custom websites to your requirements, from UIUX mockup designs to frontend conversion, with an intuitive admin panel for seamless management on any or latest frameworks.",
  },
  {
    title: "Application Design Development",
    animationData: mobileapp,
    link: "/services/mobile-app-design-and-development",
    description:
      "UIUX mockups to functional screens, including an admin panel for effortless management. Creating fully custom mobile applications in Flutter, React Native, Ionic and Swift.",
  },
  {
    title: "UI/UX and Graphic Designing",
    animationData: uiux,
    link: "/services/graphic-designing",
    description:
      "Designing pixel-perfect website and app UIUX, logos, icons, vector art, stationary, business cards, and digital collaterals - all tailored to your unique requirements.",
  },
  {
    title: "SEO and ASO",
    animationData: seo,
    link: "/services/seo-and-aso",
    description:
      "Mastering On-page SEO, Off-page SEO, Local SEO, Technical SEO, and Mobile App SEO. Our experts craft the perfect strategy aligned with the search engine algorithms to increase organic traffic.",
  },
  {
    title: "Ads Management",
    animationData: googlelinkedin,
    link: "/services/ads-management",
    description:
      "Supercharge your business with targeted Google Ads and Social Media Ads Marketing, based on the targeted demographics. Elevate sales, leads, and paid traffic.",
  },
  {
    title: "Social Media Marketing",
    animationData: socialmedia,
    link: "/services/social-media-marketing",
    description:
      "Planning, designing, and executing monthly strategies by attracting buyers directly sharing posts in groups, and creating compelling blog posts to increase organic traffic.",
  },
  {
    title: "eCommerce Management",
    animationData: eCommercemanagement,
    link: "/services/eCommerce-management",
    description:
      "Our eCommerce Management Services include anything from Amazon Seller Accounts to Shopify Store Management. Let us handle your eCommerce operations on Amazon, eBay, Etsy, Walmart, and Shopify.",
  },
  {
    title: "Book and eBook Writing",
    animationData: ebook,
    link: "/services/book-&-eBook-writing",
    description:
      "Premium eBook writing services for Authors. Our expert writers turn your vision into a captivating book that elevates your brand.",
  },
  {
    title: "Maintenance and Support",
    animationData: maintenance,
    link: "/services/maintenance-and-support",
    description:
      "Providing ongoing maintenance and support services to keep your website or mobile application up to date, live, working perfectly and running.",
  },
  {
    title: "Live Chat Support and Sales",
    animationData: support,
    link: "/services/sales-and-support",
    description:
      "Your 24/7 Front Desk Officers - providing customer support to your traffic and engage them to generate sales and offer support based on the SOP’s.",
  },
  {
    title: "Promotional Branding",
    animationData: customPatches,
    link: "/services/promotional-branding",
    description:
      "Elevate your brand with our Promotional Branding services. Order custom patches, metal badges, and tailored apparel like t-shirts, hoodies, caps, and jackets. We offer a wide range of options to meet your specific branding needs.",
  },
];

const Earth1 = () => {
  const [places, setPlaces] = useState([]);
  const [isHover, setisHover] = useState(false);
  const [isHover1, setisHover1] = useState(false);
  const [isHover2, setisHover2] = useState(false);
  const [isHover3, setisHover3] = useState(false);
  const globeEl = useRef();

  const [hoverStates, setHoverStates] = useState(
    Array(servicesData.length).fill(false)
  );
  const [hoverStatesmenu, setHoverStatesmenu] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    setMousePosition({
      x: e.clientX,
      y: e.clientY,
    });
  };

  const handleMouseEnter = (index) =>
    setHoverStates(hoverStates.map((state, i) => (i === index ? true : state)));
  const handleMouseLeave = () =>
    setHoverStates(Array(servicesData.length).fill(false));

  useEffect(() => {
    // load data
    fetch(data)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then(({ features }) => setPlaces(features))
      .catch((error) => console.error("Error loading places:", error));
  }, []);

  useEffect(() => {
    // Optional: Auto-rotate the globe
    globeEl.current.controls().autoRotate = true;
    globeEl.current.controls().autoRotateSpeed = 1.2; // Control the speed of rotation

    // Adjust globe scale
    globeEl.current.pointOfView({ altitude: 2 }, 2000); // Adjust altitude for zoom
  }, []);

  return (
    <div className="leftboxearthcon">
      <div
        className="leftboxearth boxtopmargin blur-container1"
        style={{ overflow: "visible" }}
      >
        <div className="hometitlegrid mx-1">
          <div className="line">
            <div
              className="hometitlegridflex"
              style={{ justifyContent: "space-evenly" }}
              onMouseEnter={() => setisHover(true)}
              onMouseLeave={() => setisHover(false)}
            >
              <Lottie
                animationData={idea}
                loop={!isHover}
                isStopped={!isHover}
                autoplay={isHover}
                className="widthheighticon"
              />
              <span style={{ display: "flex", justifyContent: "space-evenly" }}>
                <h1 className="hometitle text-center mx-2 home-title-mob">
                  IDEA
                </h1>
              </span>
            </div>
            <span className="divider"></span>
            <div
              className="hometitlegridflex"
              style={{ justifyContent: "space-evenly" }}
              onMouseEnter={() => setisHover1(true)}
              onMouseLeave={() => setisHover1(false)}
            >
              <Lottie
                animationData={design}
                loop={!isHover1}
                isStopped={!isHover1}
                autoplay={isHover1}
                className="widthheighticon"
              />
              <h1 className="hometitle text-center mx-2 home-title-mob">
                DESIGN
              </h1>
            </div>
          </div>
          <div className="line">
            <div
              className="hometitlegridflex"
              style={{ justifyContent: "space-evenly" }}
              onMouseEnter={() => setisHover2(true)}
              onMouseLeave={() => setisHover2(false)}
            >
              <Lottie
                animationData={code}
                loop={!isHover2}
                isStopped={!isHover2}
                autoplay={isHover2}
                className="widthheighticon"
              />
              <h1 className="hometitle mx-2 text-center home-title-mob">
                CODE
              </h1>
            </div>
            <span className="divider"></span>
            <div
              className="hometitlegridflex"
              style={{ justifyContent: "space-evenly" }}
              onMouseEnter={() => setisHover3(true)}
              onMouseLeave={() => setisHover3(false)}
            >
              <Lottie
                animationData={market}
                loop={!isHover3}
                isStopped={!isHover3}
                autoplay={isHover3}
                className="widthheighticon"
              />
              <h1 className="hometitle mx-2 text-center home-title-mob">
                MARKETING
              </h1>
            </div>
          </div>
        </div>
        <div
          id="homescrollerpara"
          className="mb-md-2  blur-effect-para home-para"
        >
          <h6 className="mt-1 ml mb-0">
            <b>
              Ready to optimize your digital presence with a Leading IT Company?
            </b>
          </h6>
          <p className="ml mb-0 mt-0">
            Hire an Award-winning IT company for design, development, digital
            marketing and business solutions.{" "}
            <b className="core-expertise">Our Core Expertises:</b>
          </p>

          <div
            className="row"
            style={{
              margin: 0,
              padding: 0,
              marginleft: 0,
              marginright: 0,
            }}
          >
            {servicesData.map((service, index) => (
              <div
                key={index}
                className="col-md-6 col-12"
                style={{
                  // flex: "0 0 calc(30% - 0.5rem)",
                  marginBottom: "0.5rem", // Control vertical spacing
                }}
              >
                <Link to={service.link}>
                  <div
                    className="Servicecard servicecard-mob mx-2"
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={() => handleMouseLeave(index)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "fit-content",
                    }}
                  >
                    <Lottie
                      animationData={service.animationData}
                      loop={hoverStates[index]}
                      isStopped={!hoverStates[index]}
                      autoplay={hoverStates[index]}
                      style={{ width: 40, height: 40 }}
                      className="mt-1 mb-md-1 servicecardbox_icon"
                    />
                    <h5
                      style={{
                        marginLeft: "0.3rem",
                        alignSelf: "center",
                        height: "100%",
                        marginBottom: "0.1rem",
                        marginTop: "0.1rem",
                        whiteSpace: "nowrap", // Prevent wrapping
                        overflow: "hidden", // Ensure content doesn't overflow
                      }}
                      className="service-hometitle-mob"
                    >
                      {service.title}
                    </h5>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <p className="mt-1 ml">
            Register now, add your project and let our expert Project Manager
            get in touch. Experience the future of Digital and Generative AI
            innovation with DA Tech, an International top-rated IT Company.
          </p>
          <p className="mt-1 ml" style={{ paddingRight: "3rem" }}>
            Expand your brand with our comprehensive digital services, from
            concept to execution. We are recognized as a leading top-rated IT
            company, website agency, and digital marketing agency.
          </p>
          <p className="mt-1 ml" style={{ paddingRight: "3rem" }}>
            Our team crafts visually appealing and highly functional websites,
            mobile apps, and custom solutions tailored to your business needs.
            Whether you're looking to hire us or outsource your projects, DA
            Tech is your nearest IT company. With over 12+ years of experience
            serving clients across the United States, Canada, Europe, United
            Arab Emirates, Pakistan, Australia and New Zealand, we are committed
            to driving the digital success.
          </p>
        </div>
        <div className="fixedcontainer">
          <div className="d-flexbtn gap-3 gap-md-0 mt-3">
            <Link
              to="https://hrm.datechmarketing.com/client-signup"
              className="mx-md-4"
            >
              <GetStartedbtn btnvalue="Get Started" iconbtn={getStarted} />
            </Link>
            <Link to="/services" className="mx-md-2">
              <Custobtn btnvalue="Services" iconbtn={services} />
            </Link>
          </div>
          <div className="row">
            <div className="col-1 mt-1">
              <p className="paraverti">
                <div className="rotate-90">&#169;&nbsp;</div>2018&nbsp; –
                &nbsp;2025
              </p>
            </div>
            <div className="col-11 mt-2 blur-container">
              <div className="blur-effect">
                <Swiper
                  slidesPerView="auto"
                  centeredSlides={false}
                  freeMode={true}
                  autoplay={{
                    delay: 0,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  loop={true}
                  grabCursor={false}
                  shortSwipes={false}
                  longSwipes={false}
                  modules={[Autoplay, FreeMode]}
                  className="mySwiper mb-2 mt-2"
                  speed={5000}
                  breakpoints={{
                    // when window width is >= 320px
                    320: {
                      slidesPerView: 2,
                      spaceBetween: 5,
                    },
                    // when window width is >= 480px
                    480: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    // when window width is >= 640px
                    640: {
                      slidesPerView: 4,
                      spaceBetween: 20,
                    },
                  }}
                  //onSlideChange={handleSlideChange}
                >
                  <SwiperSlide>
                    <img
                      src="/carousel1/1.png"
                      alt="DA Tech, an award-winning website agency in Central America, delivers cutting-edge
digital solutions for businesses."
                      title="Award-Winning Website Agency in Central America"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to="https://www.upwork.com/freelancers/~0121b5ac3c5d855695"
                      target="__blank"
                    >
                      <img
                        src="/carousel1/2.png"
                        alt="DA Tech, an award-winning design company in North America, offers innovative and
creative digital solutions for businesses."
                        title="North America's Award-Winning Design Company"
                        className="largecimg"
                        style={{
                          objectFit: "contain",
                          width: "10vw",
                          height: "12vh",
                        }}
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to="https://www.guru.com/freelancers/da-tech/reviews"
                      target="__blank"
                    >
                      <img
                        src="/carousel1/3.png"
                        className="largecimg"
                        alt="DA Tech, an award-winning IT company in the Pacific region, excels in delivering topnotch digital services and solutions."
                        title="Award-Winning IT Company in the Pacific"
                        style={{
                          objectFit: "contain",
                          width: "10vw",
                          height: "12vh",
                        }}
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to="https://clutch.co/profile/da-tech"
                      target="__blank"
                    >
                      <img
                        src="/carousel1/4.png"
                        alt="DA Tech, the leading mobile app company in South America, specializes in creating
robust, user-friendly mobile applications for global markets.
"
                        title="South America’s Premier Mobile App Company"
                        className="largecimg"
                        style={{
                          objectFit: "contain",
                          width: "10vw",
                          height: "12vh",
                        }}
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel1/5.png"
                      alt="DA Tech, an award-winning software company in Europe, provides bespoke software
solutions tailored to meet global business needs.
"
                      title="Europe’s Award-Winning Software Company"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel1/6.png"
                      alt="DA Tech offers custom software development services in the Middle East, creating
tailored digital solutions for businesses with global ambitions."
                      title="Custom Development Services in the Middle East"
                      className="largecimg mt-1"
                      style={{
                        objectFit: "contain",
                        width: "9vw",
                        height: "11vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to="https://www.goodfirms.co/company/da-tech"
                      target="__blank"
                    >
                      <img
                        src="/carousel1/7.png"
                        alt="DA Tech, an award-winning website development agency in Africa, crafts engaging and
responsive websites for businesses looking to expand globally."
                        title="Africa’s Award-Winning Website Development Agency"
                        className="largecimg"
                        style={{
                          objectFit: "contain",
                          width: "10vw",
                          height: "12vh",
                        }}
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel1/8.png"
                      alt="DA Tech, an award-winning IT company in North America, provides comprehensive IT
solutions and services to businesses across the region."
                      title="North America’s Award-Winning IT Company"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel1/9.png"
                      alt="DA Tech, an award-winning UIUX design agency in the Pacific, creates visually appealing
and user-friendly digital experiences."
                      title="Pacific Region’s Award-Winning UIUX Design Agency"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel1/10.png"
                      alt="DA Tech, a top digital marketing company in South America, delivers effective online
marketing strategies for business growth."
                      title="South America’s Leading Digital Marketing Company"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link to="https://fiverr.com/datech" target="__blank">
                      <img
                        src="/carousel1/11.png"
                        alt="DA Tech, an expert ads agency in Europe, specializes in managing and optimizing digital
ad campaigns across various platforms."
                        title="Europe’s Expert Ads Agency"
                        className="largecimg"
                        style={{
                          objectFit: "contain",
                          width: "10vw",
                          height: "12vh",
                        }}
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to="https://www.dmca.com/site-report/thedatech.com"
                      target="__blank"
                    >
                      <img
                        src="/carousel1/12.png"
                        alt="DA Tech provides expert LinkedIn Ads management services in the Middle East,
connecting businesses with professionals."
                        title="LinkedIn Ads Management in the Middle East"
                        className="largecimg"
                        style={{
                          objectFit: "contain",
                          width: "10vw",
                          height: "12vh",
                        }}
                      />
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel1/13.png"
                      alt="DA Tech, a top YouTube Ads management agency in Africa, helps businesses reach a
broader audience with engaging video content."
                      title="YouTube Ads Management in Africa"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Link
                      to="https://www.bark.com/en/gb/company/da-tech/lKYJG/"
                      target="__blank"
                    >
                      <img
                        src="/carousel1/barkprofessionals.jpg"
                        alt="DA Tech, a top social media marketing agency in Central America, enhances brand
visibility and engagement across platforms."
                        title="Central America’s Premier Social Media Marketing Agency"
                        className="largecimg"
                        style={{
                          objectFit: "contain",
                          width: "10vw",
                          height: "12vh",
                        }}
                      />
                    </Link>
                  </SwiperSlide>
                </Swiper>
                <Swiper
                  slidesPerView="auto"
                  centeredSlides={false}
                  freeMode={true}
                  autoplay={{
                    delay: 0,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  loop={true}
                  grabCursor={false}
                  shortSwipes={false}
                  longSwipes={false}
                  modules={[Autoplay, FreeMode]}
                  className="mySwiper"
                  speed={5000}
                  dir="rtl"
                  breakpoints={{
                    // when window width is >= 320px
                    320: {
                      slidesPerView: 2,
                      spaceBetween: 5,
                    },
                    // when window width is >= 480px
                    480: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    // when window width is >= 640px
                    640: {
                      slidesPerView: 4,
                      spaceBetween: 20,
                    },
                  }}
                >
                  <SwiperSlide>
                    <img
                      src="/carousel2/1.png"
                      alt=" DA Tech offers professional content writing services in North America, creating engaging
and impactful content for businesses."
                      title="North America’s Expert Content Writing Services"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel2/2.png"
                      alt="DA Tech specializes in eBook and book writing services in the Pacific, turning ideas into
compelling narratives."
                      title="Pacific Region’s Leading eBook and Book Writing Agency"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel2/3.png"
                      alt="DA Tech provides expert web maintenance services in South America, ensuring your
website runs smoothly and securely."
                      title="Reliable Web Maintenance Services in South America"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel2/4.png"
                      alt="DA Tech offers reliable app maintenance services in Europe, keeping your mobile
applications up-to-date and optimized."
                      title="Expert App Maintenance Services in Europe"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "11vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel2/5.png"
                      alt="DA Tech provides 24/7 live chat support and sales services in the Middle East, enhancing
customer engagement and satisfaction."
                      title="24/7 Live Chat Support and Sales in the Middle East"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "11vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel2/6.png"
                      alt="DA Tech is an award-winning provider of IT services in Central America, offering
innovative digital solutions."
                      title="Award-Winning IT Services in Central America"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel2/7.png"
                      alt="DA Tech, an award-winning design company in North America, delivers creative and
aesthetically pleasing digital solutions."
                      title="North America's Award-Winning Design Company"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel2/8.png"
                      alt="DA Tech, an award-winning IT company in the Pacific, is known for excellence in digital
services and solutions."
                      title="Award-Winning IT Company in the Pacific Region"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel2/9.png"
                      alt="DA Tech, a premier development company in South America, creates impactful and
scalable digital solutions for businesses."
                      title="Top Development Company in South America"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel2/10.png"
                      alt="DA Tech, an award-winning software company in Europe, provides bespoke software
solutions tailored to meet business needs."
                      title="Award-Winning Software Company in Europe"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel2/11.png"
                      alt="DA Tech specializes in custom development services in the Middle East, offering tailored
digital solutions for businesses."
                      title="Custom Development Services in the Middle East"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel2/12.png"
                      alt="DA Tech is an award-winning website development agency in Africa, offering engaging
and responsive web development services."
                      title="Comprehensive Website Development in Africa"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel2/13.png"
                      alt="DA Tech, a top mobile app development company in North America, creates robust and
user-friendly mobile applications."
                      title="Mobile App Development Services in North America"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel2/14.png"
                      alt="DA Tech, an award-winning UIUX and graphic design agency in the Pacific, delivers
visually appealing digital experiences."
                      title="UIUX and Graphic Design Experts in the Pacific"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel2/15.png"
                      alt="DA Tech offers expert SEO and ASO services in South America, boosting online visibility
and app store rankings."
                      title="SEO and ASO Services in South America"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/angular.png"
                      alt="DA Tech provides strategic Google Ads management services in Europe, driving targeted
traffic and increasing online presence."
                      title="Google Ads Management Services in Europe"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel2/17.png"
                      alt="DA Tech offers effective LinkedIn Ads management services in the Middle East,
connecting businesses with the right professionals."
                      title="LinkedIn Ads Management Experts in the Middle East"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel2/18.png"
                      alt="DA Tech provides comprehensive YouTube Ads management services in Africa, helping
businesses reach a broader audience."
                      title="YouTube Ads Management Services in Africa"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel2/19.png"
                      alt="DA Tech, a top social media marketing agency in Central America, enhances brand
visibility and engagement."
                      title="Social Media Marketing Experts in Central America"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel2/20.png"
                      alt="DA Tech offers expert content writing services in North America, creating compelling and
engaging content for businesses."
                      title="Professional Content Writing Services in North America"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel2/21.png"
                      alt="DA Tech specializes in eBook and book writing services in the Pacific, transforming ideas
into captivating narratives."
                      title="eBook and Book Writing Services in the Pacific"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>

                  <SwiperSlide>
                    <img
                      src="/carousel2/22.png"
                      alt="DA Tech provides reliable web maintenance services in South America, ensuring your
website operates smoothly and securely."
                      title="Reliable Web Maintenance in South America"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel2/23.png"
                      alt="DA Tech offers professional app maintenance services in Europe, keeping mobile
applications up-to-date and performing optimally."
                      title="App Maintenance Services in Europe"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel2/sap.png"
                      alt=" DA Tech offers 24/7 live chat support and sales services in the Middle East, ensuring
customer engagement and satisfaction."
                      title="Live Chat Support and Sales Services in the Middle East"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>

                  <SwiperSlide>
                    <img
                      src="/carousel2/facebookhome.png"
                      alt="DA Tech, an award-winning IT company in Canada, excels in delivering innovative digital
solutions for businesses."
                      title="Award-Winning IT Company in Canada"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel2/awsexpert.jpg"
                      alt="DA Tech, an award-winning IT company in Canada, excels in delivering innovative digital
solutions for businesses."
                      title="Award-Winning IT Company in Canada"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "8vw",
                        height: "10vh",
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/carousel2/openAL.png"
                      alt="DA Tech, an award-winning digital solutions provider in Australia, specializes in
innovative and customized digital services for businesses."
                      title="Award-Winning Digital Solutions in Australia"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>

                  <SwiperSlide>
                    <img
                      src="/carousel2/tiktokhome.png"
                      alt="DA Tech offers expert content writing services in North America, creating compelling and
engaging content for businesses."
                      title="Professional Content Writing Services in North America"
                      className="largecimg"
                      style={{
                        objectFit: "contain",
                        width: "10vw",
                        height: "12vh",
                      }}
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="newearthbox  earth-desktopview">
        <Globe
          globeImageUrl="/Earth/datech-mark.jpg"
          backgroundImageUrl=""
          ref={globeEl}
          labelsData={places}
          labelLat={(d) => d.properties.latitude}
          labelLng={(d) => d.properties.longitude}
          labelText={(d) => d.properties.name}
          labelSize={(d) => Math.sqrt(d.properties.pop_max) * 4e-4}
          labelDotRadius={(d) => Math.sqrt(d.properties.pop_max) * 4e-4}
          labelColor={() => "rgba(255, 165, 0, 0.75)"}
          labelResolution={2}
          backgroundColor="#00000000"
        />
      </div>
    </div>
  );
};

export default Earth1;
