import React, { useState } from "react";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import { Tooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Helmet } from "react-helmet";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css"; // Import Skeleton styles
import back from "../FILEJSON/other pages and includes/Back.json";

function BlogSkeleton() {
  const [selectedCategory, setSelectedCategory] = useState("All");

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Blog",
    name: "DA Tech Blog",
    url: "https://thedatech.com/blogs",
    description:
      "Stay updated with the latest insights, trends, and tips in custom development, eCommerce, and digital marketing with DA Tech's blog.",
  };

  return (
    <div className="servicemargin">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>
      <div className="row mx-2">
        <div className="col-1 mt-2">
          <BootstrapTooltip title="Previous Page">
            <Link to="/">
              <Lottie
                animationData={back}
                loop={false}
                style={{ width: 40, height: 40 }}
                id="backbtnport"
              />
            </Link>
          </BootstrapTooltip>
        </div>
        <div className="col-md-11 col-11 d-flex justify-content-md-center justify-content-end">
          <h1 className="subpagestitle mb-0 mt-2 mx-1">BLOGS</h1>
        </div>
      </div>
      <div
        className="btnsergridcontainer"
        style={{
          justifySelf: "center",
        }}
      >
        <div className="" style={{ width: "100vw" }}>
          <button
            className="blogcategorybtn"
            style={{
              width: "100%",
              backgroundColor: "transparent",
              outline: "none",
              textDecoration: "none",
            }}
            onClick={() => setSelectedCategory("All")}
          >
            <SkeletonTheme baseColor="#000000A5" highlightColor="#808080">
              <Skeleton count={1} height="40px" width={`50vw`} />
            </SkeletonTheme>
          </button>
          {/* Empty category buttons, they can be dynamically added if needed */}
        </div>
      </div>
      <div className="blogcontainer" style={{ width: "100vw !important" }}>
        <SkeletonTheme baseColor="#000000A5" highlightColor="#808080">
          <Skeleton
            height="100vw"
            count={3}
            width="100%"
            style={{ marginBottom: "1rem" }}
          />
        </SkeletonTheme>
      </div>
    </div>
  );
}

export default BlogSkeleton;
