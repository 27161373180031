import React, { useEffect, useRef } from "react";
import "./Loader.css"; // Create this CSS file for styling

const Loader = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.playbackRate = 0.5;

      const handlePause = () => {
        if (videoElement.paused) {
          videoElement.play();
        }
      };

      videoElement.addEventListener("pause", handlePause);

      return () => {
        videoElement.removeEventListener("pause", handlePause);
      };
    }
  }, []);

  return (
    <div className="loader-container">
      <img
        ref={videoRef}
        className="loader-video"
        src="/page-loader-video/video.gif"
        alt="Loading..."
        autoPlay
        muted
        loop
        playsInline
      />
    </div>
  );
};

export default Loader;
