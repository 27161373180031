import "./service.css";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";
// import required modules
import { FreeMode, Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { HowtogetStart } from "./HowtogetStart";
import Lottie from "lottie-react";
import services from "../../Component/FILEJSON/services/services.json";
import customer from "../../Component/FILEJSON/services/custom.json";
import website from "../../Component/FILEJSON/services/website.json";
import mobileapp from "../../Component/FILEJSON/services/mobileapp.json";
import ebook from "../../Component/FILEJSON/services/ebook.json";
import googlelinkedin from "../../Component/FILEJSON/services/google linkedin youtube ads.json";
import support from "../../Component/FILEJSON/services/livechat.json";
import socialmedia from "../../Component/FILEJSON/services/smm.json";
import uiux from "../../Component/FILEJSON/services/home design.json";
import seo from "../../Component/FILEJSON/services/home marketing.json";
import maintenance from "../../Component/FILEJSON/services/support.json";
import back from "../../Component/FILEJSON/other pages and includes/Back.json";
import eCommercemanagement from "../../Component/FILEJSON/other pages and includes/ecommercemanagement.json";
import customPatches from "../../Component/FILEJSON/other pages and includes/custompatchesServices.json";
import { useState } from "react";
import { Helmet } from "react-helmet";

const servicesData = [
  {
    title: "Custom Development",
    animationData: customer,
    link: "/services/custom-development",
    description:
      "Robust custom development for web applications, CRM, ERP, Portals, eCommerce, Service Booking, HRM, Live Chat Box, Website Admin Panel, POS System or any backend.",
  },
  {
    title: "Website Design and Development",
    animationData: website,
    link: "/services/website-design-and-development",
    description:
      "Tailoring fully custom websites to your requirements, from UIUX mockup designs to frontend conversion, with an intuitive admin panel for seamless management on any or latest frameworks.",
  },
  {
    title: "Application Design Development",
    animationData: mobileapp,
    link: "/services/mobile-app-design-and-development",
    description:
      "UIUX mockups to functional screens, including an admin panel for effortless management. Creating fully custom mobile applications in Flutter, React Native, Ionic and Swift.",
  },
  {
    title: "UI/UX and Graphic Designing",
    animationData: uiux,
    link: "/services/graphic-designing",
    description:
      "Designing pixel-perfect website and app UIUX, logos, icons, vector art, stationary, business cards, and digital collaterals - all tailored to your unique requirements.",
  },
  {
    title: "SEO and ASO",
    animationData: seo,
    link: "/services/seo-and-aso",
    description:
      "Mastering On-page SEO, Off-page SEO, Local SEO, Technical SEO, and Mobile App SEO. Our experts craft the perfect strategy aligned with the search engine algorithms to increase organic traffic.",
  },
  {
    title: "Ads Management",
    animationData: googlelinkedin,
    link: "/services/ads-management",
    description:
      "Supercharge your business with targeted Google Ads and Social Media Ads Marketing, based on the targeted demographics. Elevate sales, leads, and paid traffic.",
  },
  {
    title: "Social Media Marketing",
    animationData: socialmedia,
    link: "/services/social-media-marketing",
    description:
      "Planning, designing, and executing monthly strategies by attracting buyers directly sharing posts in groups, and creating compelling blog posts to increase organic traffic.",
  },
  {
    title: "eCommerce Management",
    animationData: eCommercemanagement,
    link: "/services/eCommerce-management",
    description:
      "Our eCommerce Management Services include anything from Amazon Seller Accounts to Shopify Store Management. Let us handle your eCommerce operations on Amazon, eBay, Etsy, Walmart, and Shopify.",
  },
  {
    title: "Book and eBook Writing",
    animationData: ebook,
    link: "/services/book-&-eBook-writing",
    description:
      "Premium eBook writing services for Authors. Our expert writers turn your vision into a captivating book that elevates your brand.",
  },
  {
    title: "Maintenance and Support",
    animationData: maintenance,
    link: "/services/maintenance-and-support",
    description:
      "Providing ongoing maintenance and support services to keep your website or mobile application up to date, live, working perfectly and running.",
  },
  {
    title: "Live Chat Support and Sales",
    animationData: support,
    link: "/services/sales-and-support",
    description:
      "Your 24/7 Front Desk Officers - providing customer support to your traffic and engage them to generate sales and offer support based on the SOP’s.",
  },
  {
    title: "Promotional Branding",
    animationData: customPatches,
    link: "/services/promotional-branding",
    description:
      "Elevate your brand with our Promotional Branding services. Order custom patches, metal badges, and tailored apparel like t-shirts, hoodies, caps, and jackets. We offer a wide range of options to meet your specific branding needs.",
  },
];

const servicesmenu = [
  { id: 1, name: "Services", animationData: services, link: "/services" },
  {
    id: 2,
    name: "Custom Development",
    animationData: customer,
    link: "/services/custom-development",
  },
  {
    id: 3,
    name: "Website Design and Development",
    animationData: website,
    link: "/services/website-design-and-development",
  },
  {
    id: 4,
    name: "Application Design Development",
    animationData: mobileapp,
    link: "/services/mobile-app-design-and-development",
  },
  {
    id: 5,
    name: "UI/UX and Graphic Designing",
    animationData: uiux,
    link: "/services/graphic-designing",
  },
  {
    id: 6,
    name: "SEO and ASO",
    animationData: seo,
    link: "/services/seo-and-aso",
  },
  {
    id: 7,
    name: "Ads Management",
    animationData: googlelinkedin,
    link: "/services/ads-management",
  },
  {
    id: 8,
    name: "Social Media Marketing",
    animationData: socialmedia,
    link: "/services/social-media-marketing",
  },
  {
    id: 13,
    name: "eCommerce Management",
    animationData: eCommercemanagement,
    link: "/services/eCommerce-management",
  },
  {
    id: 9,
    name: "Book and eBook Writing",
    animationData: ebook,
    link: "/services/book-&-eBook-writing",
  },
  {
    id: 10,
    name: "Maintenance and Support",
    animationData: maintenance,
    link: "/services/maintenance-and-support",
  },
  {
    id: 11,
    name: "Live Chat Support and Sales",
    animationData: support,
    link: "/services/sales-and-support",
  },
  {
    id: 12,
    name: "Promotional Branding",
    animationData: customPatches,
    link: "/services/promotional-branding",
  },
];

function Services() {
  const [isback, setisback] = useState(false);
  const [hoverStates, setHoverStates] = useState(
    Array(servicesData.length).fill(false)
  );
  const [hoverStatesmenu, setHoverStatesmenu] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    setMousePosition({
      x: e.clientX,
      y: e.clientY,
    });
  };

  const handleMouseEnter = (index) =>
    setHoverStates(hoverStates.map((state, i) => (i === index ? true : state)));
  const handleMouseLeave = () =>
    setHoverStates(Array(servicesData.length).fill(false));

  const handleMouseEnter1 = (id) => {
    setHoverStatesmenu(id);
  };

  const handleMouseLeave1 = () => {
    setHoverStatesmenu(null);
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .MuiTooltip-arrow`]: {
      color: theme.palette.common.black,
    },
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: "14px",
    },
  }));

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Service",
    url: "https://thedatech.com/services",
    name: "DA Tech Services",
    serviceType: [
      "Custom Development",
      "Website Design and Development",
      "Mobile App Design and Development",
      "SEO and ASO",
      "Maintenance and Support",
      "eCommerce Management",
      "Promotional Branding",
    ],
    description:
      "Explore our range of services, including custom software development, website design, eCommerce management, and more.",
    provider: {
      "@type": "Organization",
      name: "DA Tech",
      sameAs: "https://thedatech.com",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "5",
      reviewCount: "120",
    },
  };

  return (
    <div className="row servicemargin">
      <Helmet>
        <title>
          Hire an Award-winning IT company | Design, Development, Digital
          Marketing and Business Solutions | DA Tech
        </title>
        <meta
          name="description"
          content="Award-Winning IT Company: Design, Development, Digital Marketing and Business Solutions provider
near me. From creative design to robust developments and marketing with 24/7 live chat support, we
can handle everything."
        />
        <meta
          name="keywords"
          content="award-winning it company near me, development, design, digital marketing, business solutions"
        />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>
      <div className="col-12">
        <div className="btnsergridcontainer mx-3 ">
          <div
            className="col-1"
            onMouseMove={handleMouseMove}
            onMouseEnter={() => setisback(true)}
            onMouseLeave={() => setisback(false)}
          >
            <BootstrapTooltip
              title="Previous Page"
              placement="bottom"
              PopperProps={{
                anchorEl: {
                  getBoundingClientRect: () => ({
                    top: mousePosition.y + 10, // Adjust tooltip position below the mouse
                    left: mousePosition.x - 40, // Adjust for horizontal centering
                    width: 0,
                    height: 0,
                  }),
                },
              }}
            >
              <Link to="/">
                <Lottie
                  animationData={back}
                  loop={isback}
                  isStopped={!isback}
                  autoplay={isback}
                  style={{ width: 40, height: 40 }}
                  id="backbtnport"
                />
              </Link>
            </BootstrapTooltip>
          </div>
          <div className="col-11">
            <h1 className="text-center subpagestitle mb-0">Services</h1>
            <p
              className="text-center mb-0 paragraphmargin"
              style={{ fontFamily: "CFNotche-Bold" }}
            >
              Hire an Award-winning IT company for design, custom development,
              digital marketing and business solutions. Sign up now and
              effortlessly add your project in our advanced Project Management
              App.
            </p>
          </div>
        </div>
        <div className="btnsergridcontainer" style={{
          marginBottom:"2rem",
          marginTop:'1rem'}}
          >
          <Swiper
            slidesPerView={11}
            spaceBetween={10}
            className="mySwiper swiper-menu-services"
            breakpoints={{
              // when window width is >= 320px
              320: {
                slidesPerView: 4,
                spaceBetween: 5,
              },
              // when window width is >= 480px
              480: {
                slidesPerView: 6,
                spaceBetween: 5,
              },
              // when window width is >= 640px
              640: {
                slidesPerView: 11,
                spaceBetween: 10,
              },
            }}
          >
            {servicesmenu.map((menu) => {
              return (
                <SwiperSlide className="swiperfaqbgt" key={menu.id}>
                  <BootstrapTooltip
                    title={menu.name}
                    onMouseEnter={() => handleMouseEnter1(menu.id)}
                    onMouseLeave={() => handleMouseLeave1(menu.id)}
                    key={menu.id}
                  >
                    <Link to={menu.link} style={{ color: "#ff6600" }}>
                      <Lottie
                        animationData={menu.animationData}
                        loop={hoverStatesmenu === menu.id}
                        isStopped={!hoverStatesmenu !== menu.id}
                        autoplay={hoverStatesmenu === menu.id}
                        id="servicemenuciricon"
                        style={{
                          display:'flex',
                          justifyContent:'center',
                          alignItems:'center',
                          
                        }}
                      />
                    </Link>
                  </BootstrapTooltip>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        <div className="row mt-1 d-flex">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            freeMode={true}
            autoplay={{
              delay: 1000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            loop={true}
            modules={[FreeMode, Autoplay]}
            className="mySwiper p-2"
            breakpoints={{
              // when window width is >= 320px
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              // when window width is >= 480px
              480: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              // when window width is >= 640px
              640: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
            }}
          >
            {servicesData.map((service, index) => (
              <SwiperSlide key={index} >
                <Link to={service.link}>
                  <div
                    className="Servicecard service-service-card mx-auto"
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={() => handleMouseLeave(index)}
                    // style={{height:'25rem !important'}}
                  >
                    <Lottie
                      animationData={service.animationData}
                      loop={hoverStates[index]}
                      isStopped={!hoverStates[index]}
                      autoplay={hoverStates[index]}
                      style={{ width: 40, height: 40 }}
                      className="mt-1 mb-md-1 servicecardbox_icon"
                    />
                    <h5>{service.title}</h5>
                    <p>{service.description}</p>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <HowtogetStart />
      </div>
    </div>
  );
}

export default Services;
