import React, { lazy, Suspense } from "react";
import "./App.css";
import "./Pages/Application/application.css";
import "./Pages/Services/service.css";
import "./Pages/Portfolio/portfolio.css";
import "./Pages/Contact/contact.css";
import "./Pages/FAQ/faq.css";
import { Routes, Route } from "react-router-dom";
import Navbar from "./Component/Navbar/Navbar";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"; // Import Skeleton
import "react-loading-skeleton/dist/skeleton.css"; // Import Skeleton styles
import Loader from "./Component/Loader/Loader"; // Import your custom Loader

import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import BlogSkeleton from "./Component/Skeletons/BlogSkeleton";
import BlogDetailSkeleton from "./Component/Skeletons/BlogDetailSkeleton";
// Lazy load your components
import Home from "./Pages/Home/Home";
import Job from "./Pages/Job/Job";
import FAQ from "./Pages/FAQ/FAQ";
import Services from "./Pages/Services/Services";
import ContextMenu from "./Component/ContextMenu/ContextMenu";

//const Home = lazy(() => import("./Pages/Home/Home"));
const Blogs = lazy(() => import("./Pages/Blogs/Blogs"));
const Contact = lazy(() => import("./Pages/Contact/Contact"));
const Portfolio = lazy(() => import("./Pages/Portfolio/Portfolio"));
// const Services = lazy(() => import("./Pages/Services/Services"));
const ServiceDetail = lazy(() => import("./Pages/Services/ServiceDetail"));
const Career = lazy(() => import("./Pages/Career/Career"));
// const FAQ = lazy(() => import("./Pages/FAQ/FAQ"));
const Testimonials = lazy(() => import("./Pages/Testimonials/Testimonials"));
const Application = lazy(() => import("./Pages/Application/Application"));
const EVerification = lazy(() => import("./Pages/EVerification/EVerification"));
const BlogDetail = lazy(() => import("./Pages/Blogs/BlogDetail"));
const TermsCondition = lazy(() =>
  import("./Pages/TermsCondition/TermsCondition")
);
const SolutionDetail = lazy(() => import("./Pages/Application/SolutionDetail"));
const PortfolioForm = lazy(() => import("./Pages/PortfolioForm"));
const PortfolioDetail = lazy(() => import("./Pages/Portfolio/PortfolioDetail"));
const About = lazy(() => import("./Pages/About/About"));

function App() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [contextMenu, setContextMenu] = useState({ show: false, x: 0, y: 0 });

  useEffect(() => {
    // Set loading to true when the page changes, but avoid the loader on the home page
    if (location.pathname !== "/") {
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 5000); // Adjust the timeout duration as needed

      return () => clearTimeout(timer);
    } else {
      setLoading(false); // Directly show home page without delay
    }
  }, [location]);

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
      setContextMenu({
        show: true,
        x: event.clientX,
        y: event.clientY,
      });
    };

    const handleClick = () => {
      setContextMenu({ show: false, x: 0, y: 0 });
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("click", handleClick);
    };
  }, []);

  // Conditionally choose the fallback based on the route
  const getFallback = () => {
    if (location.pathname === "/blogs") {
      // Show Skeleton loader for Blog and BlogDetail pages
      return <BlogSkeleton />;
    } else if (location.pathname.startsWith("/blogs/")) {
      // Show Skeleton loader for BlogDetail page
      return <BlogDetailSkeleton />;
    } else {
      // Show the custom Loader for all other pages
      return loading ? <Loader /> : null;
    }
  };

  return (
    <>
      <Navbar />
      <Suspense fallback={getFallback()}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route
            path="/portfolio/:category_name"
            element={<PortfolioDetail />}
          />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services/:name" element={<ServiceDetail />} />
          <Route path="/privacy-policy" element={<Career />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/faq/:slug" element={<FAQ />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/solutions" element={<Application />} />
          <Route path="/eVerification" element={<EVerification />} />
          <Route path="/terms-&-condition" element={<TermsCondition />} />
          <Route path="/blogs/:title" element={<BlogDetail />} />
          <Route path="/solutions/:name" element={<SolutionDetail />} />
          <Route path="/form" element={<PortfolioForm />} />
          <Route path="/jobs" element={<Job />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </Suspense>
      {contextMenu.show && (
        <ContextMenu
          x={contextMenu.x}
          y={contextMenu.y}
          onClose={() => setContextMenu({ show: false, x: 0, y: 0 })}
        />
      )}
    </>
  );
}

export default App;
